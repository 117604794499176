<template>
    <span>
      <Tooltip
        class="my-4 mr-4"
        color="error"
        text="Rejeitar"
        top
      >
      <v-btn :rounded="big" :icon="!big" :small="!big" :class="big ? 'error' : ''" @click.stop="openConfirmationDialog = true" v-bind="$attrs"
          v-on="$listeners">
        <v-icon
          :color="big ? '' : 'success'"
        >
          mdi-close
        </v-icon> {{big ? 'Rejeitar' : ''}}
      </v-btn>
      </Tooltip>
        
      <DialogExplanation 
        v-if="openConfirmationDialog" 
        title="Rejeitar" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="submit" 
        @on-cancel="openConfirmationDialog = false">
      </DialogExplanation>
  
 
    </span>
  </template>
  
  <script>
    import DialogExplanation from '@/components/ui/DialogExplanation.vue';
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'uiIconReject',
      props:{
        big:{
            typeof: Boolean,
            default: false
        }
      },
      components: {
        DialogExplanation,
        Tooltip
      },
      data() {
        return {
          openConfirmationDialog: false,
        }
      },
      methods: {
        submit(e) {
          this.openConfirmationDialog = false
          this.$emit('on-submit', e)
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  </style>