<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Edição de Colaborador</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <v-card v-else>
          <v-tabs
            v-model="tab"
            background-color="primary"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab>
              Geral
              <v-icon>mdi-search-web</v-icon>
            </v-tab>

            <v-tab>
              Trabalho
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-tab>

            <v-tab>
              Férias
              <v-icon>mdi-caravan</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              :key="1"
            >
              <v-container fluid>
                <worker method="update" :worker="worker"></worker>
              </v-container>
            </v-tab-item>
            <v-tab-item
              :key="2"
            >
              <v-container fluid>
                <WorkerWork :worker_id="$route.params.id"/>
              </v-container>
            </v-tab-item>

            <v-tab-item
              :key="3"
            >
              <v-container fluid>
                <Holidays :asComponent="true" :worker_id="$route.params.id"/>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
  </section>
</template>
<script>
import WorkerApi from "@/api/Worker.js";
import Worker from '../../../components/workers/Worker.vue';
import WorkerWork from '@/components/workers/work/WorkerWork.vue'
import Holidays from '@/views/holidays/Holidays.vue'

export default {
  components: {
    Worker,
    WorkerWork,
    Holidays
  },
  beforeCreate(){
    document.title = "GPD - Edição de Colaboradores";
  },
  data: () => ({
    tab: null,
    worker: {},
    loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "workers.update"])) {
      this.$router.push('/admin');
    }

    WorkerApi.find(this.$route.params.id).then(({data}) => { this.worker = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Colaboradores',
          disabled: false,
          to: '/admin/workers-settings/workers',
          exact: true,
        },
        {
          text: 'Edição de Colaborador',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
