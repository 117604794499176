<template>
    <span>
      <Tooltip
        class="my-4 mr-4"
        color="success"
        text="Aprovar"
        top
      >
      <v-btn :rounded="big" :icon="!big" :small="!big" :class="big ? 'success' : ''" @click.stop="openConfirmationDialog = true" v-bind="$attrs"
          v-on="$listeners">
        <v-icon
          :color="big ? '' : 'success'"
        >
          mdi-check
        </v-icon> {{big ? 'Aprovar' : ''}}
      </v-btn>
      </Tooltip>
        
      <DialogConfirmation 
        v-if="openConfirmationDialog" 
        title="Aprovar" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="submit" 
        @on-cancel="openConfirmationDialog = false">
          Tem a certeza que pretende aprovar esta linha?
      </DialogConfirmation>
  
 
    </span>
  </template>
  
  <script>
    import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'uiIconApprove',
      props:{
        big:{
            typeof: Boolean,
            default: false
        }
      },
      components: {
        DialogConfirmation,
        Tooltip
      },
      data() {
        return {
          openConfirmationDialog: false,
        }
      },
      methods: {
        submit() {
          this.openConfirmationDialog = false
          this.$emit('on-submit')
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  </style>