<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-card>
          <v-card-title class="primary white--text">
            <v-icon left dark class="mr-2">mdi-bell</v-icon>
            Notificações
            <v-spacer></v-spacer>
            <v-btn dark rounded color="red" @click="readAll">Ler Tudo</v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-timeline dense>
            <v-timeline-item
              v-for="(notification, index) in visibleNotifications"
              :key="notification.id"
              left
              :color="getIcon(notification)['color']"
              :icon="getIcon(notification)['icon']"
            >
              <v-card class="mx-2">
                <v-card-title :class="{ unread: !notification.read }">
                  {{ notification.title }}
                </v-card-title>
                <v-card-subtitle class="text-caption">
                  {{ notification.creation }}
                </v-card-subtitle>
                <v-card-text>
                  {{ notification.description }}
                </v-card-text>
                <v-card-actions>
                  <v-row>
                    <v-col cols="12" md="2" v-if="notification.type == 'REJECTION'">
                      <v-btn small color="success" @click="verDiario(notification)">
                        Ver diária
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="2" v-if="notification.type == 'HOLIDAY_REJECTED' || notification.type == 'HOLIDAY_CREATED'">
                      <v-btn small color="success" @click="verFerias(notification)">
                        Ver pedido
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="2" v-if="notification.downloadable">
                      <v-btn small color="warning" :href="notification.url">
                        Download
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="2" v-if="false">
                      <v-btn small color="success" @click="markAsRead(index)">
                        Marcar como lida
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-timeline-item>
          </v-timeline>
          <v-divider></v-divider>
          <v-card-actions v-if="hasMore">
            <v-btn block color="primary" @click="loadMore">Ver mais</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notification from "@/api/Notification.js";

export default {
  created() {
    this.listNotifications();
  },
  data() {
    return {
      notifications: [],
      visibleNotifications: [],
      offset: 0,
      limit: 5,
      hasMore: true,
    };
  },
  methods: {
    verFerias(item){
      this.$router.push({ path: "holidays/" + item.url });
    },
    verDiario(item) {
      this.$router.push({ path: "diaries/search/" + item.url });
    },
    listNotifications() {
      Notification.list().then(({ data }) => {
        if (data.length > 0) {
          data.forEach((notification) => {
            let dataObject = new Date(notification.created_at);

            notification.data.creation =
              dataObject.toLocaleDateString("pt-PT") +
              " " +
              dataObject.getHours() +
              ":" +
              dataObject.getMinutes();

            notification.data.read = notification.read_at != null;

            notification.data.color = notification.data.read
              ? "grey"
              : this.getIcon(notification.data).color;

            notification.data.icon = this.getIcon(notification.data).icon;

            this.notifications.push(notification.data);
          });
          this.loadMore(); // Exibe o primeiro lote
        } else {
          this.hasMore = false; // Sem mais notificações
        }
      });
    },
    loadMore() {
      const start = this.visibleNotifications.length;
      const end = start + this.limit;
      this.visibleNotifications.push(...this.notifications.slice(start, end));
      if (this.visibleNotifications.length >= this.notifications.length) {
        this.hasMore = false; // Oculta o botão quando tudo foi carregado
      }

    },
    markAsRead(index) {
      this.visibleNotifications[index].read = true;
    },
    readAll() {
      this.notifications.forEach((notification) => {
        notification.read = true;
      });
      this.visibleNotifications.forEach((notification) => {
        notification.read = true;
      });
      Notification.read().then(() => {
          this.read = true;

          this.items.forEach(notification => {

            notification.read = true;
            

          });
          this.canRead = false;
          this.$emit('read-notifications')
      });
    },
    getIcon(notification) {
      switch (notification.type) {
        case "HOLIDAY_CREATED":
          return {
            icon: "mdi-caravan",
            color: "indigo",
          };
        case "HOLIDAY_ACCEPT":
          return {
            icon: "mdi-check",
            color: "success",
          };
        case "HOLIDAY_REJECTED":
          return {
            icon: "mdi-close",
            color: "error",
          };
        case "xls":
          return {
            icon: "mdi-file-excel",
            color: "success",
          };
        case "xml":
          return {
            icon: "mdi-xml",
            color: "success",
          };
        case "warning":
          return {
            icon: "mdi-alert",
            color: "warning",
          };
        default:
          return {
            icon: "comment-text-outline",
            color: "success",
          };
      }
    },
  },
};
</script>

<style scoped>
.unread {
  font-weight: bold;
}
.v-timeline-item {
  text-align: left;
}
</style>
