import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Dashboard from '../views/DashboardV2.vue'
import Notifications from '../views/Notifications.vue'
import NotFound from '../views/NotFound.vue'
import Roles from '../views/settings/roles/Roles.vue'
import Works from '../views/works_settings/works/Works.vue'
import CreateWork from '../views/works_settings/works/CreateWork.vue'
import UpdateWork from '../views/works_settings/works/UpdateWork.vue'
import Diaries from '../views/diaries/Diaries.vue'
import CreateDiary from '../views/diaries/CreateDiary.vue'
import UpdateDiary from '../views/diaries/UpdateDiary.vue'
import CreateRole from '../views/settings/roles/CreateRole.vue'
import UpdateRole from '../views/settings/roles/UpdateRole.vue'
import Users from '../views/settings/users/Users.vue'
import System from '../views/settings/system/System.vue'
import Configurations from '../views/settings/system/Configurations.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import Machines from '../views/works_settings/machines/Machines.vue'
import CreateMachine from '../views/works_settings/machines/CreateMachine.vue'
import UpdateMachine from '../views/works_settings/machines/UpdateMachine.vue'
import Categories from '../views/workers_settings/categories/Categories.vue'
import CreateCategory from '../views/workers_settings/categories/CreateCategory.vue'
import UpdateCategory from '../views/workers_settings/categories/UpdateCategory.vue'
import Workers from '../views/workers_settings/workers/Workers.vue'
import CreateWorker from '../views/workers_settings/workers/CreateWorker.vue'
import UpdateWorker from '../views/workers_settings/workers/UpdateWorker.vue'
import Units from '../views/works_settings/units/Units.vue'
import CreateUnit from '../views/works_settings/units/CreateUnit.vue'
import UpdateUnit from '../views/works_settings/units/UpdateUnit.vue'
import Layout from '../Layout.vue'
import Logs from '../views/logs/Manage.vue'
import UnitsManagement from '../views/units_management/Manage.vue'
import Exportacoes from '../views/files/FilesExport.vue'

import Companies from '../views/companies/Manage.vue'
import Clients from '../views/clients/Manage.vue'

import Calendar from '../views/settings/calendar/Calendar.vue'

import InternalAdmin from '../views/internal_admin/InternalAdmin.vue'

import Import from '../views/settings/import/Import.vue'
import Scores from '@/views/user_scores/Scores.vue'

import QRCode from '@/views/qrcode/QRCode.vue'
import RegisterWorkerQRCode from '@/views/qrcode/RegisterWorker.vue'


import Holidays from '../views/holidays/Holidays.vue'
import CreateHoliday from '../views/holidays/CreateHoliday.vue'
import UpdateHoliday from '../views/holidays/UpdateHoliday.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login,
        name: "Login"
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/admin',
                component: Dashboard
            },
            {
                path: '/admin/holidays',
                component: Holidays
            },
            {
                path: '/admin/holidays/create',
                component: CreateHoliday
            },
            {
                path: '/admin/holidays/:id',
                component: UpdateHoliday
            },
            {
                path: '/admin/qr-code',
                component: QRCode
            },
            {
                path: '/admin/qr-code/register-worker-diary/:work_id/:front_id',
                component: RegisterWorkerQRCode
            },
            {
                path: '/admin/internal-admin',
                component: InternalAdmin
            },
            {
                path: '/admin/notifications',
                component: Notifications,
            },
            {
                path: '/admin/machines',
                component: Machines
            },
            {
                path: '/admin/machines/create',
                component: CreateMachine,
            },
            {
                path: '/admin/machines/:id',
                component: UpdateMachine,
            },
            {
                path: '/admin/works-settings/units',
                component: Units
            },
            {
                path: '/admin/works-settings/units-measure',
                component: UnitsManagement
            },
            {
                path: '/admin/works-settings/units/create',
                component: CreateUnit,
            },
            {
                path: '/admin/works-settings/units/:id',
                component: UpdateUnit,
            },
            {
                path: '/admin/works-settings/works',
                component: Works,
            },
            {
                path: '/admin/works-settings/works/create',
                component: CreateWork,
            },
            {
                path: '/admin/works-settings/works/:id',
                component: UpdateWork,
            },
            {
                path: '/admin/diaries',
                component: Diaries,
            },
            {
                path: '/admin/diaries/search/:code',
                component: Diaries,
            },
            {
                path: '/admin/diaries/create',
                component: CreateDiary,
                name: 'create'
            },
            {
                path: '/admin/diaries/create/:work_id/:start_date',
                component: CreateDiary,
                name: 'create'
            },
            {
                path: '/admin/diaries/:id',
                component: UpdateDiary,
            },   
            {
                path: '/admin/diaries-offline/:id',
                name: "Offline Diary",
                component: UpdateDiary,
            },           
            {
                path: '/admin/companies',
                component: Companies,
            },
            {
                path: '/admin/scores',
                component: Scores
            },
            {
                path: '/admin/clients',
                component: Clients,
            },
            {
                path: '/admin/workers-settings/workers',
                component: Workers
            },
            {
                path: '/admin/workers-settings/workers/create',
                component: CreateWorker,
            },
            {
                path: '/admin/workers-settings/workers/:id',
                component: UpdateWorker,
            },
            {
                path: '/admin/workers-settings/categories',
                component: Categories
            },
            {
                path: '/admin/workers-settings/categories/create',
                component: CreateCategory,
            },
            {
                path: '/admin/workers-settings/categories/:id',
                component: UpdateCategory,
            },
            {
                path: '/admin/settings/roles',
                component: Roles,
            },
            {
                path: '/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/admin/settings/users',
                component: Users
            },
            {
                path: '/admin/settings/system',
                component: System
            },
            {
                path: '/admin/settings/system-configurations',
                component: Configurations
            },
            {
                path: '/admin/settings/my-user/:id',
                component: UpdateUser
            },
            {
                path: '/admin/settings/logs',
                component: Logs
            },
            {
                path: '/admin/exports',
                component: Exportacoes
            },
            {
                path: '/admin/exports/background',
                name: 'Background',
                component: Exportacoes
            },
            {
                path: '/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/create/from-worker/:id',
                name:"UserFromWorker",
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/admin/settings/imports',
                component: Import
            },
            {
                path: '/admin/settings/calendar',
                component: Calendar
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    // Não há ninguém autenticado. Vamos guardar a route para onde queriamos ir
    console.log(to)
    if (localStorage['auth'] == 0) {
        if(to.name != 'Login')
            localStorage['redirectTo'] = to.fullPath
    }
    next();
})

export default router
