<template>
    <Dialog 
      v-bind="$attrs"
      v-on="$listeners">
      <template #content>
        <v-textarea
          dense
          outlined
          v-model="explanation"
          label="Por favor, insira uma explicação"
          class="mt-4"
        />
      </template>
      <template #footer>
          <v-btn
            color="error"
            depressed
            @click="OnSubmit"
            :disabled="disableButton"
          >
            <v-icon class="mr-2">mdi-close</v-icon>Rejeitar
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="$emit('on-cancel')"
          >
            <v-icon class="mr-2">mdi-cancel</v-icon>Cancelar
          </v-btn>
      </template>
    </Dialog>
</template>

<script>
  import Dialog from '@/components/ui/Dialog.vue';
  export default {
    name: 'uiDialogExplanation',
    data() {
      return {
        disableButton: false,
        explanation: '',
      };
    },
    components: {
      Dialog
    },
    methods: {
      OnSubmit() {
        this.disableButton = true;
        this.$emit('on-submit', this.explanation);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>