<template>
    <v-container fluid class="ma-0 pa-0">
        <v-progress-linear v-if="loading"
            indeterminate
        ></v-progress-linear>
        <v-card v-if="!loading">
            <v-card-title class="primary white--text">
                Calendário
            </v-card-title>
            <v-card-text>
                <v-row class="mt-4">
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            dense outlined
                            v-model="filter.year"
                            :items="getYears"
                            label="Ano"
                            @change="fillBaseData"
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            dense outlined
                            v-model="filter.month"
                            item-value="id"
                            item-text="month"
                            :items="getMonths"
                            label="Mês"
                            @change="fillBaseData"
                        />
                    </v-col>
                    <v-col cols="12" md="3" class="mt-n2">
                        <v-switch                        
                            inset
                            v-model="showWeekend"
                            @change="changedWeekend"
                            :label="showWeekend ? 'Esconder fins-de-semana' : 'Mostrar fins-de-semana'"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-sheet height="600">
                            <v-calendar
                                ref="calendar"
                                type="month"
                                :events="events"
                                :weekdays="weekday"                                
                                @click:event="showEvent"
                                event-overlap-mode="stack"
                                :event-overlap-threshold="10"
                            >
                                           
                            </v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
                <v-dialog :width="getDialogWidth" v-model="showCalendarContent" v-if="showCalendarContent">
                    <v-card>
                        <v-card-title class="primary white--text">Evento de calendário</v-card-title>
                        <v-card-text v-if="showCalendarObject.type == 'OBRA'">
                            <v-row class="mt-2">
                                <v-col cols="12" md="4" class="font-weight-bold">
                                    Obra:
                                </v-col>
                                <v-col cols="12" md="8">
                                    {{showCalendarObject.object.code}} - {{showCalendarObject.object.name}}
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col cols="12" md="4" class="font-weight-bold">
                                    Dia:
                                </v-col>
                                <v-col cols="12" md="8">
                                    {{getDay(showCalendarObject.start)}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4" class="font-weight-bold">
                                    Performance:
                                </v-col>
                                <v-col cols="12" md="8">
                                    {{showCalendarObject.object.performance}}%
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import Worker from '@/api/Worker.js'
import Holidays from '@/api/Holidays.js'

export default{
    name:"WorkerCalendar",
    data(){
        return{
            showCalendarContent: false,
            showCalendarObject: null,
            loading: true,
            showWeekend: false,
            weekday: [1, 2, 3, 4, 5],
            filter:{
                year: null,
                month: null
            },
            events: [],
        }
    },
    mounted(){
        const currentDate = new Date();
        this.filter.year = currentDate.getFullYear();  // Obtém o ano atual
        this.filter.month = currentDate.getMonth() + 1; // Obtém o mês atual (ajustado para 1-12)
        this.fillBaseData()
    },
    methods:{
        getDay(d){
            return new Date(d).toISOString().substr(0, 10)
        },
        showEvent ({ nativeEvent, event }) {
            if(event.type != 'OBRA')
                return
            this.showCalendarContent = false
            console.log("Native event")
            console.log(nativeEvent)
            
            
            this.showCalendarContent = true
            this.showCalendarObject = event
        },
        changedWeekend(){
            if(this.showWeekend)
                this.weekday = [0, 1, 2, 3, 4, 5, 6]
            else
                this.weekday = [1, 2, 3, 4, 5]

        },
        async fillBaseData(){
            this.events = []
            this.loading = true
            await Worker.calendar(this.filter).then((resp)  =>  {
                resp.data.forEach(element => {
                    let start = element.date + ' ' + element.started_hour + ':' + element.started_minute
                    let end = element.date + ' ' + element.finished_hour + ':' + element.finished_minute

                    let description = element.name //+ ' [' + element.performance + '%]'

                    if(this.$isMobile(screen))
                        description = element.code

                   

                    this.events.push({
                        type: 'OBRA',
                        timed: false,
                        start: new Date(start),
                        end: new Date(end),
                        color: 'blue',
                        name: description,
                        object: element
                    })
                });

                this.loading = false
            })
            let params = {
                worker_id: this.$root.session.id
            }

            await Holidays.list(params).then((resp) =>  {
                resp.data.forEach(element => {
                    if(element.approved == 'S'){
                        this.events.push({
                            type: 'FERIAS',
                            timed: false,
                            start: new Date(element.start_time),
                            end: new Date(element.end_date),
                            color: 'success',
                            name: 'Férias',
                            object: element
                        })
                    }
                });
            })
        }
    },
    computed:{
        getDialogWidth(){
            let s = this.$isMobile(screen)
            
            if(s)
                return '100%'

            return '25%'
        },
        getYears(){
            let first = 2022
            let y = []
            for(let i = first; i <= new Date().getFullYear(); i++){
                y.push(i)
            }
            return y
        },
        getMonths(){
            let months = [
                {
                    id: 1,
                    month: "Janeiro"
                },
                {
                    id: 2,
                    month: "Fevereiro"
                },
                {
                    id: 3,
                    month: "Março"
                },
                {
                    id: 4,
                    month: "Abril"
                },
                {
                    id: 5,
                    month: "Maio"
                },
                {
                    id: 6,
                    month: "Junho"
                },
                {
                    id: 7,
                    month: "Julho"
                },
                {
                    id: 8,
                    month: "Agosto"
                },
                {
                    id: 9,
                    month: "Setembro"
                },
                {
                    id: 10,
                    month: "Outubro"
                },
                {
                    id: 11,
                    month: "Novembro"
                },
                {
                    id: 12,
                    month: "Dezembro"
                }
            ]
            return months
        }
    }
}
</script>