import BaseApi from "./BaseApi";

export default class Faults extends BaseApi{
    construct() {}
  
    static async update(params) {
        return await Faults.getAxiosInstance().put(Faults.getApiUrl(),params);
    }
  
    static async getKeys(params) {
        return await Faults.getAxiosInstance().get(Faults.getApiUrl() + 'get-keys',params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/faults/";
    }
}